.cm-caret span {
	display: none !important;
}

.cookie-modal {
	z-index: 2000 !important;
}

.cm-header u {
	text-decoration: none;
}

.custom-styles {
	--ReactInputVerificationCode-itemWidth: 2.5rem;
	--ReactInputVerificationCode-itemHeight: 3.5rem;
}

.hide-sprite > div > div {
	display: none !important;
}




.pulse {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #ba000c;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(186, 0, 12, 0.4);
    animation: none;
  }
  .pulse:hover {
    animation: pulse 2s infinite;   
  }
  
  .pulseActive {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #ba000c;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(186, 0, 12, 0.4);
    animation: pulse 2s infinite;
  }  

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(186, 0, 12, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(186, 0, 12, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(186, 0, 12, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(186, 0, 12, 0.4);
      box-shadow: 0 0 0 0 rgba(186, 0, 12, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(186, 0, 12, 0);
        box-shadow: 0 0 0 10px rgba(186, 0, 12, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(186, 0, 12, 0);
        box-shadow: 0 0 0 0 rgba(186, 0, 12, 0);
    }
  }